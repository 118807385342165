
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    IconChevronRight: () => import('@/components/UI/icons/IconChevronRight.vue'),
  },
})
export default class MeetingHasMoreCard extends Vue {
  @Prop({
    required: true,
    type: Number,
  }) count!: number
}
